const animals = [
  {
    name: 'dog',
    font: 'fa-dog'
  },
  {
    name: 'cat',
    font: 'fa-cat'
  },
  {
    name: 'football',
    font: 'fa-futbol'
  },
  {
    name: 'house',
    font: 'fa-house-user'
  },
  {
    name: 'tree',
    font: 'fa-tree'
  },
  {
    name: 'crow',
    font: 'fa-crow'
  },
  {
    name: 'stopwatch',
    font: 'fa-stopwatch'
  },
  {
    name: 'clock',
    font: 'fa-clock'
  },
  {
    name: 'guitar',
    font: 'fa-guitar'
  },
  {
    name: 'apple',
    font: 'fa-apple-alt'
  },
  {
    name: 'aeroplane',
    font: 'fa-avianex'
  },
  {
    name: 'cake',
    font: 'fa-birthday-cake'
  },
  {
    name: 'bug',
    font: 'fa-bug'
  },
];

export default animals;