import React, { useState, useEffect } from 'react';
import './card.css';
import animals from '../words/animal';

const getWord = (name) => {
  const hiddenWord = name.split('');
  for (var i = 0; i < name.length; i++) {
    if (i % 2 === 0) {
      hiddenWord[i] = name[i];
    } else {
      hiddenWord[i] = '';
    }
  }

  return hiddenWord.join('');
}

export default function Card() {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const name = animals[currentCardIndex].name;
  const [userInput, setInput] = useState(getWord(name));

  useEffect(() => {
    setInput(getWord(name))
  }, [currentCardIndex]);

  const checkAnswer = () => {
    if (name === userInput.toLowerCase()) {
      alert('Congratulations!! You win');
      setCurrentCardIndex((prev) => (prev + 1) % animals.length);
    } else {
      alert('ohh no..Try again!!');
    }
  }

  return (
    <div className="card-container">
      <div className="icon-container">
        <i class={`fas ${animals[currentCardIndex].font}`}></i>
      </div>
      <input className="user-input" value={userInput} onChange={(e) => setInput(e.target.value)} />
      <button onClick={checkAnswer} className='user-button'><span>Check</span></button>
    </div>
  )
}
